import { createSlice } from '@reduxjs/toolkit';

const ReturnsLineChartSlice = createSlice({
    name: 'returnsLineChart',
    initialState: {
        returnsData : null,
        isReturnsFetching : true
    },
    reducers: {
        setReturnsData(state, action){
            state.returnsData = action.payload
        },
        setIsReturnsFetching(state, action){
            state.isReturnsFetching = action.payload
        }
    },
});

export const {setReturnsData, setIsReturnsFetching } = ReturnsLineChartSlice.actions;

export default ReturnsLineChartSlice.reducer;

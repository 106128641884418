
/*eslint-disable*/
import { LineChart, Line, Tooltip, XAxis, YAxis,Brush, ResponsiveContainer} from 'recharts';
import { ArrowDownCircleIcon, ArrowUpCircle } from "lucide-react";
import { useSelector, useDispatch } from 'react-redux';
import { setIsBtnClick, setType } from './PnlLineChartSlice';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect , useRef} from 'react';


const PnlLineChartTemplate = ({unRealizedPnlData, handlePnlType, CustomLegend, CustomTooltip}) => {

    const isBtnClick = useSelector(state => state.pnlLineChart.isBtnClick);
    const type = useSelector(state => state.pnlLineChart.type);
    const targetSymbol = useSelector(state => state.pnlLineChart.targetSymbol);
    const pnlType = useSelector(state => state.pnlLineChart.pnlType);
    const dispatch = useDispatch();
    const chartRef = useRef(null);
    const options = {
        chart : {
            type : 'line'
        },
        xAxis: {
        type: 'datetime',
        title: {
            text: 'Date',
        },
        },
        yAxis: {
        title: {
            text: 'PnL',
        },
        },
        series: [
            {
              name: targetSymbol? `${targetSymbol?.tradingSymbol} ${type} Pnl` : `Total ${type} Pnl`,
              data: unRealizedPnlData?.map(item => [
                new Date(item.date).getTime(), // Converts date to timestamp
                targetSymbol ? item[`${pnlType}${type}Pnl`] : item[`total${type}Pnl`]
              ]),
              color: '#8884D8',
            }
        ]
    }
    
    useEffect(() => {
        const chart = chartRef.current?.chart;
        const endDate = new Date();
        if(chart){
            chart.xAxis[0].setExtremes(
                Date.UTC(2024, 9, 1),
                Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
              );
        }
    })
    return (
        <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-12 ">
            <div className='relative h-full px-2 py-4 border-[1px] rounded-md shadow-md w-full'>
                <h2 className="text-lg font-semibold text-center mb-4">{ targetSymbol? `${targetSymbol?.tradingSymbol} ${type} Pnl` : `Total ${type} Pnl`}</h2>
                {targetSymbol && <div className="flex-row -mt-4 mb-2">
                    {/* <p className="text-sm font-semibold">{targetSymbol?targetSymbol:"Select a stock to see PnL data"}</p>  */}
                    {
                        pnlType==='realized'?
                        <span
                        class="inline-flex items-center rounded-md
                        bg-green-50 px-2 py-1 text-xs font-semibold text-green-700
                        ring-1 ring-inset ring-green-600/20 cursor-pointer" onClick={handlePnlType}>
                            {pnlType.toLocaleLowerCase()} P&L
                        </span>
                        :
                        <span
                        class="inline-flex items-center rounded-md
                        bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-700
                        ring-1 ring-inset ring-purple-600/20 cursor-pointer" onClick={handlePnlType}>
                            {pnlType.toLocaleLowerCase()} P&L
                        </span>
                    }
                </div> 
                }
                <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} ref={chartRef}/>
                
                <div className="flex justify-center mt-2" >
                        <CustomLegend />
                </div>
                <div className="absolute top-0 right-0 group p-2 h-[40px]">
                    <button
                    onClick={()=> dispatch(setIsBtnClick(!isBtnClick))}
                    className="bg-white border border-slate-200 rounded-md font-semibold text-sm p-2 inline-flex space-x-2 items-center w-24 z-20">
                            <svg class="w-7 h-7 text-indigo-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16M8 14h8m-4-7V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"/>
                            </svg>
                            <span className="mr-1 font-semibold">{(type==='undefined'|| !type)?'Type':type}</span>
                            {
                                !isBtnClick?
                                <ArrowDownCircleIcon size={24}/>
                                :<ArrowUpCircle size={24}/>
                            }
                    </button>
                    <ul className={
                        `${isBtnClick?"":"hidden " } mt-1 top-full min-w-[80px] bg-white/90 border border-slate-200 p-2 rounded-lg shadow-lg`
                    }>
                        {
                            ["DTD","WTD","MTD","YTD","LTD"].map((value,index)=>{
                                return(
                                    <li key={index}>
                                        <p className={
                                            `text-slate-800 ${type===value?" bg-slate-200 ":""}
                                            flex items-center p-2 text-sm`
                                        } onClick={()=>{
                                            dispatch(setType(value));
                                            dispatch(setIsBtnClick(false))
                                        }}>
                                            <span className="whitespace-nowrap">{value}</span>
                                        </p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div> 
        
            </div>
            </div>
    )
}

export default PnlLineChartTemplate;
import { createSlice } from '@reduxjs/toolkit';
import UnrealizedPnlCellRenderer from './UnrealizedPnlCellRenderer';
import { strategyLists } from '../presentation/PositionsGrid';

const positionGetter = (params) => {
    return params.value === undefined? "" :Number(params.value).toLocaleString('en-IN',{
        maximumFractionDigits:0,
        manimumFractionDigits:0,
    })
}
const costGetter = (params) => {
    return params.value === undefined? "" :Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
}
const avgCostGetter = (params) => {
    
    return params.value === undefined? "" :Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
}
const latestPriceGetter = (params) => {
    return params.value === undefined? "" :Number(params.value).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
}
const unRealizedPnlGetter = (params) => {
    return Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
} 
const curMarketValueGetter = (params) => {
    return Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
}

const betaValueFormatter = (params) => {
    return params.value === undefined? "" :params.value?.toFixed(3);
}

const initialState = {
    filterText : "",
    columnOptions: 
    [
        {
            headerName: "  ",  
            headerClass: 'align-left', 
            cellStyle: { textAlign: "left" },
            width: 10,
            sort: null
        }, 
        {
            headerName: "Date", field: 'date',
            width: 120, 
            headerClass: 'align-left', 
            cellStyle: { textAlign: "left" },
            sort: null
        }, 
        {
            headerName: "Trading Symbol", field: 'tradingSymbol',
            tooltipField: "Trading Symbol",
            
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            sort: null  
        }, 
        {
            headerName: "Broker", field: 'positionCompositeKey.broker', 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width: 120,
            sort: null
        }, 
        {
            headerName: "Strategy Name", field: "strategyLists", 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:180,
            sort: null,
            valueGetter: (params) => {
                const strategyId = params?.data?.positionCompositeKey?.strategyId; 
                return strategyId && strategyLists ? strategyLists.filter(str => str.strategy_id === strategyId )[0]?.strategy_name : ''            }    
        },
        
        {
            headerName: "Position", field: 'position',valueFormatter:positionGetter,
            tooltipField: "Position",
            width: "130px", 
            headerClass: "ag-right-aligned-header",
            sort: null  
        },  
        {
            headerName: "Open Cost", field: 'openCost',valueFormatter:costGetter,
            width: 120,
            headerClass: "ag-right-aligned-header",
            sort: null
        
        },   
        
        {
            headerName: "Avg Cost Per Share", field: 'averageCostPerShare', valueFormatter:avgCostGetter,
            headerClass: "ag-right-aligned-header",
            width: 150,
            sort: null,
            
        },   
        {
            headerName: "Latest Price", field: 'latestPrice', valueFormatter:latestPriceGetter,
            headerClass: "ag-right-aligned-header",
            width: 120,
            sort: null
        },  
        {
            headerName: "Unrealized Pnl", field: 'unrealizedPnl',valueFormatter:unRealizedPnlGetter, 
            headerClass: "ag-right-aligned-header",
            width: 200,
            sort: null,
            cellRenderer:UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter
            }
        
        },   
        {
            headerName: "Current Value", field: 'curMarketValue', valueFormatter:curMarketValueGetter,
            headerClass: "ag-right-aligned-header",
            width: 140,
            sort: null
        },
        {
            headerName: "Beta", field: 'beta', valueFormatter:betaValueFormatter,
            headerClass: "ag-right-aligned-header",
            width: 110,
            sort: null
        }
    ]
}

const PositionTableOptionSlice = createSlice({
    name:'positionTableOption',
    initialState,
    reducers:{
        setPositionTableOptionsData(state, action){
            state.columnOptions = action.payload
        },
        setFilterText(state, action){
            state.filterText =  /^[0-9]/.test(action.payload) 
            ? action.payload.substring(1) // Remove the first character if it's a number
            : action.payload.replace(/[^a-zA-Z0-9]/g, ""); //Allow only number and alphabets
        }
    }
})

export const {setPositionTableOptionsData, setFilterText} = PositionTableOptionSlice.actions;
export default PositionTableOptionSlice.reducer;

/* eslint-disable */
import { setShowChart } from '../../GraphTemplate/container/GraphTemplateSlice' 
import { useDispatch, useSelector } from 'react-redux';
import { setShowDerivative, setShowForm } from '../../GraphTemplate/container/GraphTemplateSlice';
import { setGraphTradingSymbol, setGraphZenId, setShowPopover, setSiblingEquity} from './MainTemplateSlice';
import { setTargetNode } from '../../GraphTemplate/container/GraphTemplateSlice';
import Popover from './Popover';
import { setDerivativeIsIndex, setDerivativePopover, setShowChart as setDerivativeShowChart } from '../../DerivativeGraph/container/DerivativeGraphSlice';
import { useGlobalState } from "components/globalVariable";
import { useRealtimeZenSecurities2 } from 'components/getAllSourcesSecurities';

const PopoverTemplate = ({cyRef, elements}) => {
    const dispatch = useDispatch();
    const cy = cyRef.current;
    const popoverPosition = useSelector(state => state.mainTemplate.popoverPosition);
    const popoverNode = useSelector(state => state.graphTemplate.popoverNode);
    const isIndex = useSelector(state => state.mainTemplate.isIndex);
    const isSector = useSelector(state => state.mainTemplate.isSector);
    // const isDerivative = useSelector(state => state.derivativeGraphTemplate.isDerivative);
    const [clientId] = useGlobalState("clientId");
    const zenSecuritiesData1 = useRealtimeZenSecurities2();


    const isDerivative = false;
    const listStyle = {
        position : 'absolute',
        left : popoverPosition.x ,
        top : popoverPosition.y,
        width : '12%'
    }
    const listItemStyle = {
        textDecoration : 'none', 
        color : 'black',
        display : 'flex',
        alignItems : 'center'
    }

    const imageStyle = {
        height : '25px',
        width : '20px',
        marginRight : '3%',
    }
    const showDerivative = (e) => {
       e.stopPropagation();
       dispatch(setDerivativePopover(popoverNode));
       dispatch(setDerivativeIsIndex(isIndex));

       //Popover node data 
       sessionStorage.setItem(popoverNode?.data()?.properties?.name, JSON.stringify(popoverNode.json()));

       //Popover node sector data
       sessionStorage.setItem(`${popoverNode?.data()?.parent} Sector`, JSON.stringify(elements.filter((element) => element.data.parent === popoverNode.data().properties.industry)));
       
       //Popover node parent data
       sessionStorage.setItem(popoverNode?.data()?.parent, JSON.stringify(popoverNode?.parent()?.data()));
       
       //Index data 
       sessionStorage.setItem(`${popoverNode?.data()?.properties?.name} Index`, JSON.stringify(popoverNode.parent().connectedEdges().map((node) => node.source().data())));
       
       //Index edges
       sessionStorage.setItem(`${popoverNode?.data()?.properties?.name} edge`,JSON.stringify(popoverNode.parent().connectedEdges().map((node) => node.data())))
    //    dispatch(setSiblingEquity(elements.filter((element) => element.data.parent === popoverNode.data().properties.industry)))
       dispatch(setShowPopover(false));
       e.stopPropagation()
    }

    const showChart = (e) => {
       e.stopPropagation()
        dispatch(setTargetNode(popoverNode.data().properties.name))
        dispatch(setShowPopover(false))   
        dispatch(setShowChart(true))
        e.stopPropagation()
        // cyRef.current.center()
        // cyRef.current.panBy({x: 500, y: 0})
    }
    const showForm = (e) => {
       e.stopPropagation();
        dispatch(setShowPopover(false));
        if(!clientId){
            alert("Kindly select the trade profile!");
            return;
          }
        dispatch(setGraphTradingSymbol(popoverNode?.data().properties.name));
        dispatch(setShowForm(true));
    }
    
    const hideForm = (e) => {
       e.stopPropagation();    
        dispatch(setShowPopover(false));     
        dispatch(setShowForm(false));
    }

    return (
        <Popover
            showForm = {showForm}
            showChart = {showChart}
            showDerivative = {showDerivative}
            hideForm = {hideForm}
            listItemStyle ={listItemStyle}
            listStyle = {listStyle}
            isIndex = {isIndex}
            isSector = {isSector}
            isDerivative = {isDerivative}
            popoverNode = {popoverNode}
            imageStyle = {imageStyle}
        />
    )
}

export default PopoverTemplate;
/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedClients : null
};

const DemoNavbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedClients(state, action){
        state.selectedClients = action.payload
    }
  }
});

export const {setSelectedClients} = DemoNavbarSlice.actions;
export default DemoNavbarSlice.reducer;

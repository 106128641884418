/*eslint-disable*/
import { AgGridReact } from 'ag-grid-react'
import TableRefresher from 'components/TableRefreshComponet/TableRefresher'
import { useGlobalState } from 'components/globalVariable'
import React from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ShimmerTable } from 'react-shimmer-effects'
import config from '../../config'
import { useRef } from 'react'

function GreeksGrid() {

    const { dates,broker, security, exchange, portfolio, entity, strategy } = useSelector(state => state.filter);
    const [clientId]=useGlobalState("clientId");
    const [accountIds] = useGlobalState("accountIds");
    const [clientName] = useGlobalState('clientName');
    const [rowData, setRowData] = useState();
    const gridApiRef = useRef(null);
    const [count,setCount] = useState(0);
    const [isFetching,setIsFetching] = useState(true);

    const  cellClickedListener = ( ) => {
        setCount(count + 1);
    };

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { background: "rgb(243, 238, 238)" };
        }
    }

    const defaultColDef = {
        sortable: true,
        editable: false,
        filter: true,
       
        headerClass:{textAlign:"right"},
        cellStyle: params => {
            if (params.value < 0) {
                return { color: "red", display: "flex",justifyContent: "flex-end"};
            } else {
                return {display: "flex",justifyContent: "flex-end"};
            }
        }
    }

    const gridStyle = useMemo(() => ({ height:"510px",  width : '91vw' }), []);
    const filterStyle = useMemo(() => ({ height: '50px', width : '100%' }), []);

    const valueFormatter = (params)=>{
        const formattedValue = params.value.toLocaleString('en-IN', {
            maximumFractionDigits: 4,
            minimumFractionDigits: 4
        });
        return formattedValue;
    }

    const columnOptions = useMemo(()=> [
        {
            headerName: "  ",
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:10,
            sort: null 
        },
        {
            headerName: "Trading Symbol", field: 'trading_symbol',
            headerClass: 'align-left',
            cellClass: 'align-left',
            cellStyle:{textAlign:"left",},
            width: 200,
            sort: null   
        },
        {
            headerName: "Last Price", field: 'last_price', 
            headerClass: "ag-right-aligned-header",
            cellClass: 'align-right',
            width: 120,
            sort: null   
        },
        {
            headerName: "Strike",field: 'strike',
            headerClass: "ag-right-aligned-header",
            cellClass: 'align-right',
            width:120,
            sort: null,
        },
        {
            headerName: "Expiry", field: 'expiry',
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:130,
            sort: null 
        },
        {
            headerName: "Moneyness", field: 'moneyness', 
            headerClass: 'align-left',
            cellClass: 'align-left',
            cellStyle:{textAlign:"left",},
            width: 160,
            sort: null   
        }, 
        {
            headerName: "Impl Vol",field: 'implied_volatility',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:120,
            sort: null,
            
        }, 
        {
            headerName: "Delta",field: 'delta',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:120,
            sort: null,
        },
        {
            headerName: "Gamma",field: 'gamma',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:130,
            sort: null,
        },
        {
            headerName: "Theta",field: 'theta',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:120,
            sort: null,
        },
        {
            headerName: "Vega",field: 'vega',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:120,
            sort: null,
        },
        {
            headerName: "Charm",field: 'charm',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:120,
            sort: null,
        }, 
        {
            headerName: "Color",field: 'color',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:120,
            sort: null,
        }, 
        {
            headerName: "Rho",field: 'rho',
            headerClass: "ag-right-aligned-header",
            valueFormatter:valueFormatter,
            cellClass: 'align-right',
            width:110,
            sort: null,
        },  
    ])
    useEffect(() => {
        setIsFetching(true)
        const fetchData = () => {
            fetch(config.REACT_APP_DEV_URL+'indexOptionGreeks',
            {
                method: 'GET',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                },     
            })
            .then(result => result.json())
            .then(rowData => {
                setRowData(rowData);
                setTimeout(()=>setIsFetching(false),750);
            })
            .catch((err) => {
                console.log("render error");
            });
        }
        fetchData();
        const intervalId = setInterval(fetchData, 120000);
       
        return(()=>{
            clearInterval(intervalId);
        })
    }, [count]);

    // useEffect(() => {
    //     console.log("Security changes : ", security[0], gridApiRef.current, gridApiRef.current?.getFilterModel());
    //     if (gridApiRef.current && rowData) {
    //         // console.log("Inside", );
    //         // const filter = {...gridApiRef.current?.getFilterModel?.(),
    //         //     trading_symbol: 
    //         //     {
    //         //         filterType: 'set',
    //         //         // type : 'equals',
    //         //         // filter:  security[0],   
    //         //         values : security
                    
    //         //     }
    //         // }
    //         // gridApiRef.current?.setFilterModel(filter);
    //         // gridApiRef.current?.onFilterChanged?.(); 
    //         // gridApiRef.current.refreshCells({ force: true });   
    //         // console.log(gridApiRef.current?.getFilterModel());   
    //         gridApiRef.current?.setColumnFilterModel?.('trading_symbol', {values : security});
    //         gridApiRef.current?.onFilterChanged?.(); 

    //     }
    // },[security, rowData];
    

    const onGridReady = (params) => 
    {
        gridApiRef.current = params.api; 
    }
  return (
    <div className=''>
        <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
                <div style={gridStyle}    className="ag-theme-alpine">
                    {isFetching?
                    <ShimmerTable row={6} col={6} />
                    :
                    <AgGridReact
                        columnDefs={columnOptions}
                        defaultColDef={defaultColDef}
                        pagination
                        paginationPageSize={20}
                        rowData={rowData}
                        getRowStyle={getRowStyle}
                        ref={gridApiRef}
                        onGridReady={onGridReady}
                    >
                    </AgGridReact>
                    }
                </div>
    </div>
  )
}

export default GreeksGrid

/* eslint-disable */
import OrderTable from "../components/OrderTable/OrderTable";
import React from "react";
import OrderFormContainer from "components/OrderForm/Field/OrderFormContainer";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Toast, ToastContainer, Button } from 'react-bootstrap';
import './Order.css'
import { useGlobalState } from "components/globalVariable";
import { useDispatch, useSelector } from "react-redux";
import { setShowOrderResponse } from "components/OrderForm/Field/orderFormSlice";
import { setOrderMessage } from "components/OrderForm/Field/orderFormSlice";
import { setOrderStatus } from "components/OrderForm/Field/orderFormSlice";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";

function OrdersGrid() {
  const [clientId] = useGlobalState("clientId");
  const dispatch = useDispatch();
  const orderMessage = useSelector(state => state.orderForm.orderMessage);
  const orderStatus = useSelector(state => state.orderForm.orderStatus);
  const showOrderResponse = useSelector(state => state.orderForm.showOrderResponse);
  const options = [
    { label: "Buy", value: "BUY", color: "blue" },
    { label: "Sell", value: "SELL", color: "black" },
  ];
  useEffect(() => {
    dispatch(setShowOrderResponse(false));
    return undefined;
  },[clientId])
  return (
    <>
<Row>
      
      <Col xs="auto" width={64}>
        <Card >
          <CardBody>
            <OrderFormContainer options={options}/>
          </CardBody>
        </Card>
      </Col>
      <Col md="auto">      
        <Card >
            <OrderTable/>
        </Card>
      </Col>
    </Row>
    <ToastContainer position="bottom-center" className="p-3" style={{position : 'relative',left:"75%", marginTop: orderStatus != 'REJECTED' ? '-14%' : '-18%', zIndex : "1000000000000"}}>
        
          {showOrderResponse && <Toast>
            <Toast.Header
              style = {{backgroundColor : orderStatus!= "ACCEPTED" ? '#ff2424':'#088761',color:'white', height :'2.5rem'}}
            >
              <strong className="me-auto ">{orderMessage !== "" ? orderStatus : "Success"}</strong>
              <Button
                variant="link"
                onClick={() => {
                  dispatch(setShowOrderResponse(false));
                  dispatch(setOrderMessage(null));
                  dispatch(setOrderStatus(null));
                }}
                className="ms-auto text-decoration-none"
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color : 'white'
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = 'white')}
                onMouseOut={(e) => (e.target.style.backgroundColor = 'transparent')}
              >
                <IoClose />
              </Button>
            </Toast.Header>
            <Toast.Body>{orderMessage !== "" ? orderMessage : orderStatus}</Toast.Body>
          </Toast>}
        
      </ToastContainer>
    </>
    
  );
}

export default OrdersGrid;

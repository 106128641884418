import { createSlice } from '@reduxjs/toolkit';
import ButtonRenderer from './ButtonRenderer';

const cellFirstRules = 
{
  'yellow-cell' : (params) => params.data && params.data.moneyness_call !== 'OTM'
};

const cellLastRules = 
{
  'yellow-cell' : (params) => {
    return params.data && params.data.moneyness_put !== 'OTM'
  }
};


const callFormatterForGamma =(params) => 
{
    return isNaN(params.value) ? params.value : (Number(params.value) * 100).toFixed(4);
};

const callFormatter = (params) => 
{
  return isNaN(params.value) ? params.value : (Number(params.value).toFixed(2));
};

// const moneynessComparator = (a, b) => 
// {
//   const order = { 'ITM': 1, 'ATM': 2, 'OTM': 3 };
//   return order[a] - order[b];
// };

const priceGetter = (params) => 
{ 
  return Number(params.value).toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
  });
};

const initialState = {
  showOrderForm : false,
  tradingSymbol : '',
  callType : "BUY",
  month: "",
  date :"",
  dateOptions: ['2024-08-16','2024-08-19','2024-09-11','2024-09-16','2024-10-16','2024-10-12'],
  dateList : [],
  monthList : [],
  symbol : 37761,
  symbolName: "NIFTY",
  niftyDateOptions: [],
  bankNiftyDateOptions: [],
  niftyStrikeOptions : [],
  bankNiftyStrikeOptions: [],
  strike:"",
  isMarketOpen : false,
  strikeList : [],
  filterModel : {},
  isFetchingFirst : true,
  isFetching : true,
  niftyEodPrice : "",
  bankNiftyEodPrice : "",
  columnOptions : [
    {
      headerName: 'CALLS',
      headerClass :'header-put',
      children: 
      [
        { 
          headerName: 'Gamma', 
          field: 'gamma-call', 
          headerClass: 'header-white', 
          width:100 ,
          valueFormatter: (params) => callFormatterForGamma(params) , 
          cellClassRules:cellFirstRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[0]
        },
        { 
          headerName: 'Vega', 
          field: 'vega-call' , 
          headerClass: 'header-white', 
          width:100 ,
          valueFormatter: (params) => callFormatter(params) , 
          cellClassRules:cellFirstRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[1]
        },
        { 
          headerName: 'Theta', 
          field: 'theta-call' , 
          headerClass: 'header-white', 
          width:100,
          valueFormatter: (params) => callFormatter(params)  , 
          cellClassRules:cellFirstRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[2]
        },
        { 
          headerName: 'Delta', 
          field: 'delta-call' , 
          headerClass: 'header-white', 
          width:100 ,
          valueFormatter: (params) => callFormatter(params) , 
          cellClassRules:cellFirstRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[3]
        },
        { 
          headerName: 'LTP', 
          field: 'ltp-call' , 
          headerClass: 'header-white', 
          width:100 ,
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params)=> ({
            value : params.value,
            tradingSymbol : params.data.key+"CE"
          }),
          cellClassRules:cellFirstRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[4]
        }
      ]
    },
    { 
      headerName: 'Trading Symbol', 
      field: 'key' ,  
      width:150,
      hide:true,
      sort : null
      // sort : sortOptions[5]
    },
    { 
      headerName: 'Strike', 
      field: 'strike' , 
      headerClass: 'header-blue', 
      width:100 , 
      filter: true,
      cellStyle: {justifyContent : 'right'},
      valueFormatter: (params) => priceGetter(params),
      // sortable : true,
      sort : "asc"
    },
    {  
      headerName: 'Underlying Zen id', 
      field: 'underlying_zen_id' ,
      width : 100, 
      hide: true,
      filter: true,
      sort : null
      // sort : sortOptions[7]
    },
    { 
      headerName: 'Expiry Date', 
      field: 'expiry_date' , 
      headerClass: 'header-blue', 
      width:200,
      filter: true,
      filterParams: {
        inRangeInclusive: true,
      },
      comparator: (date1, date2) => {
        return new Date(date1) - new Date(date2); // Ascending order
      },
      sortable : true,
      sort : 'asc'
      // sort : sortOptions[8]
    },
    // { 
    //   headerName: 'Moneyness call', 
    //   field: 'moneyness_call' , 
    //   headerClass: 'header-blue', 
    //   width:150,
    //   comparator: moneynessComparator ,
    //   hide: false,
    //   sort : "asc",
    //   // sort : sortOptions[9]
    // },
    // { 
    //   headerName: 'Moneyness put', 
    //   field: 'moneyness_put' , 
    //   headerClass: 'header-blue', 
    //   width:150,
    //   // comparator: moneynessComparator ,
    //   hide: false,
    //   sort : null,
    //   // sort : sortOptions[9]
    // },
    {
      headerName: 'PUTS',
      headerClass :'header-call',
      width:700,
      children: 
      [
        { 
          headerName: 'LTP', 
          field: 'ltp-put' , 
          headerClass: 'header-white', 
          width:100, 
          cellRenderer: ButtonRenderer, 
          cellClassRules:cellLastRules,
          cellRendererParams: (params)=> (
            {
              value : params.value,
              tradingSymbol : params.data.key+"PE",
            }
          ),
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[10]
        },
        { 
          headerName: 'Delta', 
          field: 'delta-put' , 
          headerClass: 'header-white', 
          width:100 ,valueFormatter: (params) => callFormatter(params), 
          cellClassRules:cellLastRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[11]
        },
        { 
          headerName: 'Theta', 
          field: 'theta-put' , 
          headerClass: 'header-white', 
          width:100 ,
          valueFormatter: (params) => callFormatter(params), 
          cellClassRules:cellLastRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[12]
        },
        { 
          headerName: 'Vega', 
          field: 'vega-put' , 
          headerClass: 'header-white', 
          width:100 ,
          valueFormatter: (params) => callFormatter(params), 
          cellClassRules:cellLastRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[13]
        },
        { 
          headerName: 'Gamma', 
          field: 'gamma-put' , 
          headerClass: 'header-white', 
          width:100 ,
          valueFormatter: (params) => callFormatterForGamma(params), 
          cellClassRules:cellLastRules, 
          cellStyle: {justifyContent : 'right'},
          sort : null
          // sort : sortOptions[14]
        }

      ]
    }
  ],
  sortOptions : [null, null, null, null,null,null,null,null,null,'asc',null,null, null, null, null]
};

const OptionChainSlice = createSlice({
  name: 'optionChainTemplate',
  initialState,
  reducers: {
    
    setShowOrderForm(state, action){
      state.showOrderForm = action.payload
    },
    setTradingSymbol(state, action){
        state.tradingSymbol = action.payload
    },
    setCallType(state, action){
        state.callType = action.payload
    },
    setMonth(state, action){
      state.month = action.payload
    },
    setDateList(state, action){
      state.dateList = action.payload
    },
    setMonthList(state, action){
      state.monthList = action.payload
    },
    setDate(state, action){
      state.date = action.payload
    },
    setSymbol(state, action){
      state.symbol = action.payload
    },
    setNiftyDateOptions(state, action){
      state.niftyDateOptions = action.payload
    },
    setBankNiftyDateOptions(state, action){
      state.bankNiftyDateOptions = action.payload
    },
    setSymbolName(state, action){
      state.symbolName = action.payload
    },
    setFilterModel(state, action){
      state.filterModel = action.payload
    },
    setIsFetchingFirst(state, action){
      state.isFetchingFirst = action.payload
    },
    setIsFetching(state, action){
      state.isFetching = action.payload
    },
    setSortOptions(state, action) {
      state.sortOptions = action.payload;
    },
    setColumnOptions(state, action){
      state.columnOptions = action.payload;
    },
    setNiftyStrikeOptions(state, action){
      state.niftyStrikeOptions = action.payload;
    },
    setBankNiftyStrikeOptions(state, action){
      state.bankNiftyStrikeOptions = action.payload;
    },
    setStrikeList(state, action){
      state.strikeList = action.payload;
    },
    setStrike(state, action){
      state.strike = action.payload;
    },
    setNiftyEodPrice(state, action){
      state.niftyEodPrice = action.payload;
    },
    setBankNiftyEodPrice(state, action){
      state.bankNiftyEodPrice = action.payload;
    },
    setIsMarketOpen(state, action){
      state.isMarketOpen = action.payload
    }
  }
});

export const {setShowOrderForm, setTradingSymbol, setCallType, setMonth, setDateList, 
  setMonthList, setDate, setSymbol, setNiftyDateOptions, setBankNiftyDateOptions, setSymbolName ,
  setFilterModel, setIsFetchingFirst, setIsFetching, setSortOptions,  setColumnOptions, 
  setBankNiftyStrikeOptions, setNiftyStrikeOptions, setStrikeList, setStrike, setNiftyEodPrice, 
  setBankNiftyEodPrice, setIsMarketOpen} = OptionChainSlice.actions;
export default OptionChainSlice.reducer;

// import { useSelector } from 'react-redux';
import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, Brush} from 'recharts';

const ReturnsLineChartTemplate = ({targetSymbol}) => {
    // const returnsData = useSelector(state => state.returnsLineChart.returnsData);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const CustomTooltip = ({ active, payload, label }) => {
        let month = new Date(payload[0]?.payload?.date).getMonth();
        let dateNumber = new Date(payload[0]?.payload?.date).getDate();
        if (active && payload && payload.length) {
          return (
            <div className="bg-slate-300/80 h-10 w-auto px-2 py-2 rounded-sm">
              <p className="text-sm font-semibold">{`${monthNames[month]?.slice(0,3)} ${dateNumber<10?('0'+dateNumber):dateNumber} :  ${Math.round(Number(payload[0].value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    })}%`}</p>
            </div>
          );
        }
        return null;
    };
    const CustomLegend = () => {
        return (
            <ul className="flex space-x-4">
                <li className="flex items-center">
                    <span className="block w-10 h-1" style={{ backgroundColor: '#8884D8' }} />
                    <span className="ml-2">{ targetSymbol? `${targetSymbol?.tradingSymbol} Returns` : `Total Returns`}</span> {/* Custom legend text */}
                </li>
            </ul>
        );
    };

    return(
        <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-12">
            <div className='relative w-full h-full px-2 py-4 border-[1px] rounded-md shadow-md'>
                <h2 className="text-lg font-semibold text-center mb-4">{ targetSymbol? `${targetSymbol?.tradingSymbol} Returns` : `Total Returns`}</h2>
                
                <ResponsiveContainer width="100%" height={350}>
                <LineChart data={[]}>
                    {/* <CartesianGrid strokeDasharray={"5"} vertical={false}/> */}
                    {/* <defs>
                        <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="green" stopOpacity={1} />
                        <stop offset="100%" stopColor="red" stopOpacity={1} />
                        </linearGradient>
                    </defs> */}                                     
                    <XAxis 
                        dataKey="date"
                        tickFormatter={(date) => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                        angle={-45}
                        tick={{ fontSize: 10 }}
                        dy={5}
                    />
                    <YAxis 
                        tick={{ fontSize: 10 }}
                        tickFormatter={(value) => {
                            const absValue = Math.abs(value);
                            let formattedValue;
                            
                            if (absValue >= 1_000_000) {
                                formattedValue = `${(absValue / 1_000_000)}M`;
                            } else if (absValue >= 1_000) {
                                formattedValue = `${(absValue / 1_000)}K`;
                            } else {
                                formattedValue = absValue;
                            }

                            return formattedValue;
                        }} 
                    />
                    <Brush
                        dataKey="date"
                        stroke="#608BC1"
                    />
                    {/* <div className="flex justify-center">
                        <Legend 
                            content={<CustomLegend />} 
                            verticalAlign="bottom" 
                            height={36} 
                        />
                    </div> */}
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }}/>
                    <Line type="monotone" dot = {false} dataKey={"returns"} stroke="#8884D8" strokeWidth={1.5} />
                </LineChart>
                </ResponsiveContainer>
                
                <div className="flex justify-center mt-2" >
                        <CustomLegend />
                </div>
            </div>
            </div>
       
    )
}

export default ReturnsLineChartTemplate;
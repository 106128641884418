/*eslint-disable*/
import { useMemo, useState, useEffect } from "react";
import config from "../config";


async function fetchData() {
  try {
    const response = await fetch(config.CORS_URL + 'getLatestIndexEODPrice?zen_id=37766');
    if (!response.ok) throw new Error("Error fetching EOD price");

    const data = await response.json();

    const todayDate = new Date();
    const startDate = new Date(Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()));
    const endDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth() + 2, 0));
    const day = String(endDate.getUTCDate()).padStart(2, '0');
    const month = String(endDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = endDate.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    const startingPrice = Math.round(data[0]?.close * 0.97);
    const endingPrice = Math.round(data[0]?.close * 1.03);

    const validOptionsResponse = await fetch(
      config.CORS_URL +
        `getValidOptionZenSecurities?strike=${startingPrice},${endingPrice}&name=BANKNIFTY&expiry=${formattedDate}`
    );

    if (!validOptionsResponse.ok) throw new Error("Error fetching valid options");

    const validOptions = await validOptionsResponse.json();
    return validOptions;
  } catch (error) {
    console.error("Error in fetchData:", error);
    return null; // Return null to handle errors gracefully
  }
}

//done this for memonizing the getting data
const data = fetchData();
function fetchData2(){
  return data;
}



function getNextFetchTime() {
  const now = new Date();
  const nextFetch = new Date();
  nextFetch.setHours(9, 10, 0, 0);
  //default today morning 9:10AM
 
  if (now > nextFetch) {
      nextFetch.setDate(nextFetch.getDate() + 1);
      //Fri May 17 2024 09:10:00 GMT+0530 (India Standard Time)
  }
  //If time is above 9:10AM the it is set to next days' 9:10AM
  return nextFetch.getTime();
}


export function useValidBankNiftySecurities() {
  const [fetchTime, setFetchTime] = useState(getNextFetchTime());

  useEffect(() => {
      const now = new Date().getTime();
      const timeUntilNextFetch = fetchTime - now;

      const timeoutId = setTimeout(() => {
          setFetchTime(getNextFetchTime());
      }, timeUntilNextFetch);

      return () => {
        clearTimeout(timeoutId)
      }
  }, [fetchTime]);


  const store = useMemo(() => fetchData2(), [fetchTime]);
  return store;

}
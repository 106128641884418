import { setTargetSymbol } from "components/PnlLineChart/PnlLineChartSlice";
import { setDateRange, setZenSecId } from "components/PnlLineChart/PnlLineChartSlice";
import DatePicker from 'react-datepicker';
import { Autocomplete } from '@mui/material';
import {TextField} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { useGlobalState } from 'components/globalVariable';

const PnlReturnsFilterTemplate = () => {
    const stocks = useSelector(state => state.pnlLineChart.stocks);
    const [clientName] = useGlobalState('clientName');
    const dispatch = useDispatch();
    const targetSymbol = useSelector(state => state.pnlLineChart.targetSymbol);
    const dateRange = useSelector(state => state.pnlLineChart.dateRange);
    return (
        <div className='bg-white w-full flex flex-col space-y-4 mt-3'>
        <div className='flex space-x-2 rounded-md'>
            <Autocomplete
                key={clientName}
                //whenever this changes the component will reset.
                value={targetSymbol}
                // disablePortal={false}
                options={stocks}
                clearOnEscape
                sx={{ width: 250 }}
                getOptionLabel={option => option.tradingSymbol}
                renderInput={(params) => {
                    return <TextField {...params} label="Trading Symbol" />
                }} 
                onChange={(event,value)=>{
                if(value){
                    dispatch(setTargetSymbol(value));
                    dispatch(setZenSecId(value?.zenSecId));
                }
                else{
                    dispatch(setTargetSymbol(null));
                    dispatch(setZenSecId(null));
                }
                }}
            />
            {/* {
                !stocks?.length?
                <span className={`inline-flex items-center cursor-pointer gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium
                bg-red-200 text-red-800 dark:bg-blue-800/30 dark:text-blue-500`}
                >
                No stock holdings in your account
                </span>
                :null
            }          */}
        <div className='form-control' style={{ height: '57px', width : '270px', cursor:"pointer"}}>
            <DatePicker 
                selectsRange={true}     
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => {
                    dispatch(setDateRange([update[0],update[1]]));
                    if (update[1]!==null) dispatch(setDateRange([update[0],update[1]]));
                    } 
                }
                isClearable={false}
                className="nomarg w-64"
                style={
                    {zIndex:100}
                }
                placeholderText='Choose Date Range'
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                portalId={"date-picker"}
                showIcon
                icon={
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 48 48"
                    >
                    <mask id="ipSApplication0">
                        <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                        <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                        <path
                            fill="#fff"
                            d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                        ></path>
                        </g>
                    </mask>
                    <path
                        fill="currentColor"
                        d="M0 0h48v48H0z"
                        mask="url(#ipSApplication0)"
                    ></path>
                    </svg>
                }
                //for external date z index
                >
                </DatePicker>
            </div>
        </div>
        </div>
    )
}

export default PnlReturnsFilterTemplate;
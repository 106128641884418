/* eslint-disable */
import TradesGrid from "../components/TradesGrid/container/TradesGrid";
import TradesFilter from "../components/TradesFilter/container/TradesFilter";
import PropTypes from 'prop-types';
import React, { useRef, useState } from "react";
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

const Trades = () => 
{ 
 
  return (
  <Row>
  <Col xs="auto">
      <Card>    
        <TradesGrid />
      </Card>
    </Col>
  </Row>
);
}


export default Trades;

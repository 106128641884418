/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showPopover : false,
  popoverPosition : {x : 0, y : 0},
  popoverIndexLabels : [],
  // equityDerivative : '',
  chartData : [],
  chartIndexData : [],
  isIndex : false,
  isSector : false ,
  siblingEquity : '',
  defaultZoom : '',
  firstLayoutStop : true,
  nodePosition : '',
  nodeStyle : '',
  isGraphLoaded : false,
  graphTradingSymbol : null,
};

const MainTemplateSlice = createSlice({
  name: 'mainTemplate',
  initialState,
  reducers: {
    setShowPopover(state, action){
        state.showPopover = action.payload
    },
    setPopoverPosition(state, action){
        state.popoverPosition = action.payload
    },
    setPopoverIndexLabels(state, action){
      state.popoverIndexLabels = action.payload
    },
    setChartData(state, action){
      state.chartData = action.payload
    },
    setChartIndexData(state, action){
      state.chartIndexData = action.payload
    },
    setIsIndex(state, action){
      state.isIndex = action.payload
    },
    setIsSector(state, action){
      state.isSector = action.payload
    },
    setSiblingEquity(state, action){
      state.siblingEquity = action.payload
    },
    setDefaultZoom(state, action){
      state.defaultZoom = action.payload
    },
    setFirstLayoutStop(state, action){
      state.firstLayoutStop = action.payload
    },
    setNodePosition(state, action){
      state.nodePosition = action.payload
    },
    updateNodePosition(state, action){
      const position = action.payload[1]
      state.nodePosition = {...state.nodePosition, [action.payload[0]] : {x: position.x, y : position.y}} 
    },
    setNodeStyle(state, action){
      state.nodeStyle = action.payload
    },
    setIsGraphLoaded(state, action){
      state.isGraphLoaded = action.payload
    },
    setGraphTradingSymbol(state, action){
      state.graphTradingSymbol = action.payload;
    }
    // setEquityDerivative(state, action){
    //   state.equityDerivative = action.payload
    // }
  }
});

export const { setShowPopover, setPopoverPosition, setPopoverIndexLabels, setEquityDerivative, 
  setChartData, setChartIndexData, setIsIndex, setIsSector, setSiblingEquity , setDefaultZoom,
  setFirstLayoutStop, setNodePosition, updateNodePosition, setNodeStyle , setIsGraphLoaded,
  setGraphZenId, setGraphTradingSymbol} = MainTemplateSlice.actions;
export default MainTemplateSlice.reducer;
